import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    anygiftMainColor: {
      500: '#41C395',
    },
    anygiftBlue: {
      500: '#3366CC',
    },
  },
});

export default theme;
